import axios from 'axios';
export const completeTx = async (txHash,data) => {
    try {
      const auction = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/transaction/complete/${txHash}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
          },
        }
      );
      return auction;
    } catch (error) {
      return error.response.data;
    }
  };
  