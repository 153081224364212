import React, { useState } from "react";
import Classes from "./formField.module.scss";
import ButtonClasses from "../buildOrganizationProfile.module.scss";
import { Col, Row, Checkbox } from "antd";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { validationSchema } from "./FormValidations";
import { alertMessage } from "../../../constants/alertMessage";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { validateUsername } from "../../../services/auth.service";
import Terms from "../../Terms";
import NotificationAlert  from "../../Alert";


const FormFields = ({
  setFormFieldsData,
  formFieldsData,
  irsOrganizationData,
  restData,
  accountType,
}) => {
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const [isUsernameUnique, setIsUsernameUnique] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getInviteSentModal = sessionStorage.getItem('showInviteSentModal');

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const handleUsernameExist = async (username) => {
    try {
      const trimmedUsername = username.trim();
      if(trimmedUsername.length>3){
      const authToken = localStorage.getItem("authenticationToken");
      const response = await validateUsername(authToken, trimmedUsername);

      if (response?.status === 200) {
        setIsUsernameUnique("available");
      } else {
        setIsUsernameUnique("taken");
      }
    }else{
      setIsUsernameUnique("");
    }
    } catch (error) {
      console.log("error :", error);
    }
  };
  const getFiscalSponsorEmailAddress = sessionStorage.getItem('fiscalSponsorEmailAddress')
  return (
    <>
      <Formik
        initialValues={{
          einNumber: irsOrganizationData?.ein || "",
          fiscalSponsorNonProfitName: accountType
            ? irsOrganizationData?.name
            : location?.state?.fiscalSponsorAgreementData?.accountType
            ? location?.state?.fiscalSponsorAgreementData
                ?.fiscalSponsorNonProfitName
            : "",
          fiscalSponsorEmail: getFiscalSponsorEmailAddress || (accountType
            ? irsOrganizationData?.email
            : location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.fiscalSponsorEmail
            : ""),
          nonProfitName: location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.nonprofitName
            : !accountType
            ? irsOrganizationData?.name
            : "",
          firstName: location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.firstName
            : "",
          lastName: location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.lastName
            : "",
          website: location?.state?.fiscalSponsorAgreementData
          ? location?.state?.fiscalSponsorAgreementData?.website
          : "", 
          instagram: location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.instagram
            : "", 
          facebook: location?.state?.fiscalSponsorAgreementData
          ? location?.state?.fiscalSponsorAgreementData?.facebook
          : "", 
          linkedIn: location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.linkedIn
            : "", 
          username: "",
          email: auth?.email,
          address:
            (location?.state?.fiscalSponsorAgreementData &&
              location?.state?.fiscalSponsorAgreementData?.address) ||
            "",
          addressTwo: "",
          city:
            irsOrganizationData?.city ||
            location?.state?.fiscalSponsorAgreementData?.city ||
            "",
          zipCode: location?.state?.fiscalSponsorAgreementData?.zipCode || "",
          state:
            location?.state?.fiscalSponsorAgreementData?.state ||
            irsOrganizationData?.state ||
            "",
          country:
            location?.state?.fiscalSponsorAgreementData?.country ||
            irsOrganizationData?.country ||
            "",
          missionStatement:
            location?.state?.fiscalSponsorAgreementData?.missionStatement,
          role:
            location?.state?.role ||
            restData?.role ||
            location?.state?.fiscalSponsorAgreementData?.role,
          organizationType:
            restData?.organizationType ||
            location?.state?.organizationType ||
            location?.state?.fiscalSponsorAgreementData?.organizationType,
          fiscalSponsorId: irsOrganizationData?.organizationId
            ? ""
            : location?.state?.fiscalSponsorAgreementData?.fiscalSponsorId,
          fiscalProjects: location?.state?.fiscalSponsorAgreementData
            ? location?.state?.fiscalSponsorAgreementData?.fiscalProjects
            : [auth?.id],
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          try {
            console.log("submiting form");
            setFormFieldsData((prevState) => ({
              ...prevState,
              einNumber: values?.einNumber,
              fiscalSponsorNonProfitName: values?.fiscalSponsorNonProfitName,
              fiscalSponsorEmail: values?.fiscalSponsorEmail,
              nonProfitName: values?.nonProfitName,
              firstName: values?.firstName,
              lastName: values?.lastName,
              username: values?.username,
              email: values?.email,
              address: values?.address,
              addressTwo: values?.addressTwo,
              city: values?.city,
              zipCode: values?.zipCode,
              country: values?.country,
              state: values?.state,
              missionStatement: values?.missionStatement,
              role: values?.role,
              organizationType: values?.organizationType,
              fiscalSponsorId: location?.state?.fiscalSponsorAgreementData
                ?.fiscalSponsorId
                ? location?.state?.fiscalSponsorAgreementData?.fiscalSponsorId
                : irsOrganizationData?.organizationId,
              fiscalProjects: location?.state?.fiscalSponsorAgreementData
                ?.fiscalProjects
                ? location?.state?.fiscalSponsorAgreementData?.fiscalProjects
                : [auth?.id],
            }));
            if (!formFieldsData?.profilePicture) {
              alertMessage("error", "Organization logo is required.");
            } else if (!isCheckTerms) {
              alertMessage("error", "Term & conditions checkbox is required.");
            } else {
              if (
                location?.state?.fiscalSponsorAgreementData?.accountType ===
                "defaultFiscalSponsor"
              ) {
                navigate("/selection", {
                  state: {
                    userData: {
                      profilePicture: formFieldsData?.profilePicture,
                      einNumber: values?.einNumber,
                      fiscalSponsorNonProfitName:
                        values?.fiscalSponsorNonProfitName,
                      fiscalSponsorEmail: values?.fiscalSponsorEmail,
                      nonProfitName: values?.nonProfitName,
                      firstName: values?.firstName,
                      lastName: values?.lastName,
                      username: values?.username,
                      email: values?.email,
                      address: values?.address,
                      addressTwo: values?.addressTwo,
                      city: values?.city,
                      zipCode: values?.zipCode,
                      state: values?.state,
                      country: values?.country,
                      missionStatement: values?.missionStatement,
                      role: values?.role,
                      organizationType: values?.organizationType,
                      website:
                        location?.state?.fiscalSponsorAgreementData?.website || values?.website ||
                        "",
                      facebook:
                        location?.state?.fiscalSponsorAgreementData?.facebook || values?.facebook ||
                        "",
                      instagram:
                        location?.state?.fiscalSponsorAgreementData
                          ?.instagram || values?.instagram || "",
                      linkedIn:
                        location?.state?.fiscalSponsorAgreementData?.linkedIn || values?.linkedIn ||
                        "",
                      contactNumber:
                        location?.state?.fiscalSponsorAgreementData
                          ?.contactNumber || "",
                      fiscalSponsorId:
                        location?.state?.fiscalSponsorAgreementData
                          ?.fiscalSponsorId || "",
                      fiscalProjects:
                        location?.state?.fiscalSponsorAgreementData
                          ?.fiscalProjects || "",
                    },
                  },
                });
              } else {
                navigate("/upload-document", {
                  state: {
                    userData: {
                      profilePicture: formFieldsData?.profilePicture,
                      einNumber: values?.einNumber,
                      fiscalSponsorNonProfitName:
                        values?.fiscalSponsorNonProfitName,
                      fiscalSponsorEmail: values?.fiscalSponsorEmail,
                      nonProfitName: values?.nonProfitName,
                      firstName: values?.firstName,
                      lastName: values?.lastName,
                      username: values?.username,
                      email: values?.email,
                      address: values?.address,
                      addressTwo: values?.addressTwo,
                      city: values?.city,
                      zipCode: values?.zipCode,
                      state: values?.state,
                      country: values?.country,
                      missionStatement: values?.missionStatement,
                      role: values?.role,
                      organizationType: values?.organizationType,
                      fiscalSponsorId: irsOrganizationData?.organizationId,
                      fiscalProjects: [auth?.id],
                      website: values?.website,
                     facebook: values?.facebook,
                     instagram: values?.instagram,
                     linkedIn: values?.linkedIn,
                    },
                  },
                });
              }
            }
            setSubmitting(false);
          } catch (err) {
            console.log(err, "err>");
          }
        }}
      >
        {({ handleChange, errors }) => (
          <Form>
            {console.log(errors, "errors")}
            <Row gutter={[16, 38]} className={`${Classes.formFields} w-100`}>
              {accountType === "fiscalSponsor" ||
              location?.state?.fiscalSponsorAgreementData?.accountType ===
                "defaultFiscalSponsor" ? (
                <>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>
                        Fiscal Sponsor Nonprofit Name
                      </div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="fiscalSponsorNonProfitName"
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>
                        Fiscal Sponsor Email address
                      </div>
                      <Field
                        type="email"
                        className={Classes.input}
                        name="fiscalSponsorEmail"
                        disabled
                      />
                    </div>
                  </Col>
                </>
              ) : (
                ""
              )}

              <Col lg={24} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Nonprofit Name* </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="nonProfitName"
                  />
                </div>
                <ErrorMessage
                  name="nonProfitName"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={Classes.label}>Username*</div>
                    <p
                      className={`${Classes.txt} d-flex align-items-center gap-1`}
                    >
                      {isUsernameUnique === "available" ? (
                        <>
                          <AiFillCheckCircle fontSize="20px" color="green" />
                          Username Available
                        </>
                      ) : isUsernameUnique === "taken" ? (
                        <>
                          <AiFillCloseCircle fontSize="20px" color="red" />
                          Username Already Taken
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="username"
                    onChange={(e) => {
                      // You can still use Formik's handleChange function
                      handleChange(e);
                      // Call your custom function here
                      handleUsernameExist(e.target.value);
                    }}
                    // onChange={(e) => handleUsernameExist(e.target?.value)}
                  />
                </div>
                <ErrorMessage
                  name="username"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>First Name* </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="firstName"
                  />
                </div>
                <ErrorMessage
                  name="firstName"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Last Name* </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="lastName"
                  />
                </div>
                <ErrorMessage
                  name="lastName"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Email*</div>
                  <Field
                    type="email"
                    className={Classes.input}
                    name="email"
                    // diabled='true'
                    disabled={true}
                    value={auth?.email}
                  />
                </div>
                <ErrorMessage
                  name="email"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Address*</div>
                  <Field type="text" className={Classes.input} name="address" />
                </div>
                <ErrorMessage
                  name="address"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Address 2</div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="addressTwo"
                  />
                </div>
                <ErrorMessage
                  name="addressTwo"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>City*</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="city"
                      />
                    </div>
                    <ErrorMessage
                      name="city"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>Zip Code*</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="zipCode"
                      />
                    </div>
                    <ErrorMessage
                      name="zipCode"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>State*</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="state"
                      />
                    </div>
                    <ErrorMessage
                      name="state"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>Country*</div>

                      <Field
                        type="text"
                        className={Classes.input}
                        name="country"
                      />
                    </div>
                    <ErrorMessage
                      name="country"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>Website</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="website"
                      />
                    </div>
                    <ErrorMessage
                      name="website"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>Instagram</div>

                      <Field
                        type="text"
                        className={Classes.input}
                        name="instagram"
                      />
                    </div>
                    <ErrorMessage
                      name="instagram"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[16, 16]}>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>Facebook</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="facebook"
                      />
                    </div>
                    <ErrorMessage
                      name="facebook"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                  <Col lg={12} md={24} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>LinkedIn</div>

                      <Field
                        type="text"
                        className={Classes.input}
                        name="linkedIn"
                      />
                    </div>
                    <ErrorMessage
                      name="linkedIn"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Mission Statement (50 to 600 characters)*</div>
                  <Field
                    as="textarea"
                    type="text"
                    className={Classes.input}
                    name="missionStatement"
                    rows={3}
                    disabled={
                      location?.state?.fiscalSponsorAgreementData
                        ?.missionStatement &&
                      location?.state?.fiscalSponsorAgreementData
                        ?.missionStatement?.length
                        ? true
                        : false
                    }
                  />
                </div>
                <ErrorMessage
                  name="missionStatement"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
            </Row>
            <div className="d-flex align-items-center gap-2 mt-3">
              <Checkbox
                onClick={() => setIsCheckTerms(!isCheckTerms)}
              ></Checkbox>
              <p className={`${Classes.subFormTxt}`}>
                I have read and agreed to the&nbsp;
                <span onClick={handleButtonClick}>Terms & Conditions*</span>
              </p>
            </div>
            <div className="d-flex justify-content-between align-items-center w-100 mt-4">
              <button
                onClick={() => {
                  navigate("/get-started");
                }}
                type="button"
                className={`${ButtonClasses.blueBtn} ${ButtonClasses.back}`}
              >
                Back
              </button>
              <button
                className={`${ButtonClasses.blueBtn} ${
                  !isCheckTerms && ButtonClasses.back
                }`}
                type="submit"
                disabled={!isCheckTerms}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
      <Terms
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    {getInviteSentModal && <NotificationAlert title="Success" content='An email has been sent to your fiscal sponsor. Please contact your fiscal sponsor and ask them to join the Charity NFT platform. Thank you.' />}

    </>
  );
};

export default FormFields;
