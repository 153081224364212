import React, { useEffect, useState } from "react";
import Classes from "./favourites.module.scss";
import CardImg from "../../assets/images/section/cardImg1.svg";
import { ReactComponent as Heart } from "../../assets/images/icons/heart.svg";
import Polygon from "../../assets/images/icons/polygon.svg";
import { useNavigate } from "react-router-dom";
import { removeNftToFav } from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
import { thunkLogin } from "../../store/_actions/user_action";
import { useDispatch, useSelector } from "react-redux";
import { nftListings } from "../../services/nft.service";
import { Col, Row } from "antd";
import { VideoHome } from "../../helpers/VideoHome";
import { FaVideo } from "react-icons/fa";

const Favourites = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const [favItems, setFavItems] = useState([]);
  const [isFav, setIsFav] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const authToken = localStorage.getItem("authenticationToken");

  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const checkIsFavourites = async (id) => {
    const isNftInFavorites = auth?.favourites?.includes(id);
    setIsFav(isNftInFavorites);
  };

  const getNftListings = async () => {
    try {
      const list = await nftListings(authToken);
      if (list?.data?.success === true) {
        const matchingRecords = list?.data?.data.filter((record) =>
          auth?.favourites.includes(record._id)
        );
        matchingRecords?.map((matchingRecord) => {
          checkIsFavourites(matchingRecord?._id);
        });
        setFavItems(matchingRecords);
      }
    } catch (error) {
      console.log("error :", error);
    }
  };
  useEffect(() => {
    getNftListings();
  }, []);

  const handleRemoveToFavourite = async (nftId) => {
    try {
      if (authToken) {
        const data = {
          nftId,
          authToken,
        };
        const response = await removeNftToFav(data);

        if (response?.data) {
          getNftListings();
          dispatch(
            thunkLogin(response?.data?.data, () => {
              alertMessage("success", `${response?.data?.message}`);
            })
          );
        }
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  console.log(favItems);
  return (
    <>
      <Row gutter={[20, 20]}>
        {favItems &&
          favItems?.map((favItem) => (
            <Col lg={6} md={8} sm={24} xs={24}>
              <div className={Classes.mainCard}>
                <>
                  <div>
                    {favItem?.primaryType === "image" ? (
                      <img
                        className={Classes.mainImg}
                        src={favItem?.file ? favItem?.file : CardImg}
                        alt="img"
                        onClick={() => navigate(`/nft/${favItem?._id}`)}
                      />
                    ) : (
                      <div onClick={() => navigate(`/nft/${favItem?._id}`)}>
                        <VideoHome source={favItem?.file} />
                      </div>
                    )}
                    {authToken ? (
                      <div className={Classes.actionBtn}>
                        {favItem?.primaryType === "image" ? (
                          ""
                        ) : (
                          <div
                            className={`${Classes.videoIcon} ${Classes.heart} d-flex align-items-center gap-1`}
                          >
                            <FaVideo />
                          </div>
                        )}

                        {isFav && (
                          <div
                            onClick={() => {
                              toggleActive();
                              handleRemoveToFavourite(favItem?._id);
                            }}
                            className={Classes.bg + " " + Classes.heartActive}
                          >
                            <Heart />
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div>
                      <h3 className={Classes.title}>{favItem?.name}</h3>
                      {favItem?.userId?.firstName && (
                        <span className={Classes.desc}>
                          Owned by:&nbsp;
                          <b className={Classes.owner}>
                            {favItem?.userId?.firstName}
                          </b>
                        </span>
                      )}
                    </div>
                    <img
                      className="align-self-start pt-2"
                      src={Polygon}
                      alt="icon"
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className={Classes.priceTxt}>Price</p>
                    <p className={Classes.price}>
                      {favItem?.currentPrice || "0 "}ETH
                    </p>
                  </div>
                </>
              </div>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default Favourites;
