// eslint-disable-next-line import/prefer-default-export
export const SET_THEME = "SET_THEME";
export const SET_USER = "SET_USER";
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

//WALLET
export const WALLET_PROVIDER = "WALLET_PROVIDER";
export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS";
export const SET_WALLET_EMPTY = "SET_WALLET_EMPTY";
export const SET_WALLET_ERROR = "SET_WALLET_ERROR";


//NFTS
export const SET_NFTS = "SET_NFTS";
export const SHOW_NFTS_LIST_LOADER = "SHOW_NFTS_LIST_LOADER";
export const HIDE_NFTS_LIST_LOADER = "HIDE_NFTS_LIST_LOADER";
export const SET_POPULAR_NFTS = "SET_POPULAR_NFTS";
export const SHOW_POPULAR_NFTS_LIST_LOADER = "SHOW_POPULAR_NFTS_LIST_LOADER";
export const HIDE_POPULAR_NFTS_LIST_LOADER = "HIDE_POPULAR_NFTS_LIST_LOADER";
export const SET_NFT = "SET_NFT";
export const SHOW_NFT_LIST_LOADER = "SHOW_NFT_LIST_LOADER";
export const HIDE_NFT_LIST_LOADER = "HIDE_NFT_LIST_LOADER";
export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";
export const SET_POLY_EXCHANGE_RATE = "SET_POLY_EXCHANGE_RATE";

//BET

export const SHOW_PLACE_BET_LOADER = "SHOW_PLACE_BET_LOADER";
export const HIDE_PLACE_BET_LOADER = "HIDE_PLACE_BET_LOADER";
export const PLACE_BET_STATUS = "PLACE_BET_STATUS";
export const SET_BETS = "SET_BETS";

//Transaction

export const SET_CREATE_TRANSTIOIN_LOADER = "SET_CREATE_TRANSTIOIN_LOADER";
export const CREATE_TRANSACTION = "CREATE_TRANSACTION";

//auction
export const SET_BUY_NOW_LOADER = "SET_BUY_NOW_LOADER";
export const BUY_NOW = "BUY_NOW";

//categories
export const SET_CATEGORIES_LISTING = "SET_CATEGORIES_LISTING";

//organization
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";

export const NOTIFICATIONS = "NOTIFICATIONS";

//platform settings
export const SET_PLATFORM_SETTINGS_LISTING = "SET_PLATFORM_SETTINGS_LISTING";

// Define action type for updating social media
export const UPDATE_SOCIAL_MEDIA = "UPDATE_SOCIAL_MEDIA";

// Define action type for updating notifications
export const UPDATE_NOTIFICATION_SETTINGS = "UPDATE_NOTIFICATION_SETTINGS";
