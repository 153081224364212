import React from "react";
import Classes from "./listingtable.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
function ListingTable({ listings }) {
  const { exchangeRate } = useSelector((state) => state.nft);
  const user = useSelector((state) => state.auth);
  console.log(user, "user==>");
  const navigate = useNavigate();
  const location = useLocation();
  const activeAuctionId = location?.state?.auctionId
    ? location?.state?.auctionId
    : listings?.length > 0
      ? listings[0]?._id
      : null;

  const data = activeAuctionId
    ? listings?.filter((item) => activeAuctionId !== item._id)
    : listings;

  return (
    <div className={Classes.listingTable}>
      <div className={Classes.header}>Listings</div>
      <div className={Classes.tableOuter}>
        <table>
          {data && data.length > 0 && (
            <tr className="">
              <th>MATIC Price</th>
              <th>USD Price</th>
              <th>Quantity</th>
              <th>from</th>
              <th>Action</th>
            </tr>
          )}
          {data && data.length > 0 ? (
            data.map((item, key) => (
              <tr key={item?._id}>
                <td>
                  <div className="d-flex gap-1 align-items-center">
                    <span className={Classes.boldtxt}>
                      {/* ${(item?.bidAmount * exchangeRate)?.toFixed(4) || 0} */}
                      {item?.sellingConfig?.price?.amount ||
                        item.sellingConfig?.startPrice?.amount}
                    </span>
                  </div>
                </td>
                <td>
                  <span className={Classes.txt}>
                    {" "}
                    {item.sellingMethod === 1
                      ? (
                          item?.sellingConfig?.price?.amount * exchangeRate
                        ).toFixed(4)
                      : (
                          item.sellingConfig?.startPrice?.amount * exchangeRate
                        ).toFixed(4)}
                  </span>
                </td>
                <td>
                  <span className={Classes.txt}>
                    {item?.copiesForAuction || 0}
                  </span>
                </td>
                <td>
                  <span className={`${Classes.boldtxt} ${Classes.blueTxt}`}>
                    {item?.userId?.walletAddress?.slice(0, 6)?.toUpperCase() ||
                      ""}
                  </span>
                </td>
                <td>
                  <button
                    className={Classes.blueBtn}
                    onClick={() =>
                      navigate(`/nft/${item?.nftId}`, {
                        state: { auctionId: item?._id },
                      })
                    }
                    //   disabled={loader?.status && loader?.id === item?._id}
                  >
                    {"View"}
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <span className={`${Classes.noData}`}>No data yet</span>
          )}
        </table>
      </div>
    </div>
  );
}

export default ListingTable;
