import React, { Fragment, useState } from 'react';
import Classes from '../Invite/invite.module.scss';
import { Modal } from 'antd';
import { Formik } from 'formik';
import { ThreeDots } from 'react-loader-spinner';
import { alertMessage } from '../../constants/alertMessage';
import { reportNonProfit } from '../../services/auth.service';

import { Radio, Space } from 'antd';
import { useSelector } from 'react-redux';

const radioOptions = [
  {
    id: 1,
    title: 'Spam',
    value: 'Spam',
  },
  {
    id: 2,
    title: 'Fraud',
    value: 'Fraud',
  },
  {
    id: 3,
    title: 'Child sexual exploitation or abuse',
    value: 'Child sexual exploitation or abuse',
  },
  {
    id: 4,
    title: 'Terrorism or violent extremism',
    value: 'Terrorism or violent extremism',
  },
  {
    id: 5,
    title: 'Hate speach',
    value: 'Hate speach',
  },
  {
    id: 6,
    title: 'Imminent harm to person or property',
    value: 'Imminent harm to person or property',
  },
  {
    id: 7,
    title: 'Non-consensual intimate imagery',
    value: 'Non-consensual intimate imagery',
  },
  {
    id: 8,
    title: 'Self-harm or suicide',
    value: 'Self-harm or suicide',
  },
  {
    id: 9,
    title: 'Harrasment or bullying',
    value: 'Harrasment or bullying',
  },
  {
    id: 10,
    title: 'Defamation, impersonations, false information',
    value: 'Defamation, impersonations, false information',
  },
  {
    id: 11,
    title: 'Sexually inappropriate',
    value: 'Sexually inappropriate',
  },
  {
    id: 12,
    title: 'Content infringement',
    value: 'Content infringement',
  },
  {
    id: 13,
    title: 'Malware or viruses',
    value: 'Malware or viruses',
  },
  {
    id: 14,
    title: 'Others',
    value: 'Others',
  },
];

const ReportNonProfitModal = ({ organization, isModalOpen, handleCancel }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState('');
  const [reportReason, setReportReason] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    id: '',
    title: '',
    value: '',
  });
  const auth = useSelector((state) => state?.auth);
  console.log('organization', organization)

  const authToken = localStorage.getItem('authenticationToken');

  const onChange = (e) => {
    const selectedRadioOption = radioOptions.find(
      (option) => option.id === e.target.value
    );

    setValue(e.target.value);
    setSelectedOption(selectedRadioOption);
    setError('')
  };

  const handleReportNonProfit = async (data, resetForm) => {
    try {
      setLoading(true);
      const response = await reportNonProfit(authToken, data);
      if (response?.status === 200) {
        resetForm({ values: '' });
        handleCancel();
        alertMessage('success', `${response?.data?.message}`);
        setLoading(false);
      } else {
        alertMessage('error', `${response?.message}`);
        setLoading(false);
      }
    } catch (error) {
      alertMessage('error', `${error}`);
      setLoading(false);
    }
  };

  const handleReasonState = (e) => {
    setReportReason(e?.target?.value);
  };
  React.useEffect(() => {
    if (isModalOpen) {
      setValue(null);
      setReportReason("");
      setError('')
      setSelectedOption({
        id: "",
        title: "",
        value: "",
      });
    }
  }, [isModalOpen]);
  return (
    <Modal
      title={organization?.role === `user` ? `Report User` : `Report Nonprofit Organization`}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <Formik
        initialValues={{
          reason: '',
        }}
        onSubmit={(values, { resetForm }) => {
          const data = {
            email: auth.email,
            reportedBy:auth.id,
            reportedTo:organization?._id,
            reportType: 'organization',
            reason:
              selectedOption?.id === 14 ? reportReason : selectedOption?.value,
            organizationId: organization && organization?._id,
          };

          if (data?.reason) {
            if(selectedOption?.id === 14){
              if(data?.reason?.length<50){
                return;
              }
              if(data?.reason?.length>600){
                return;
              }
            }
            handleReportNonProfit(data, resetForm);
          } else {
            setError('Report reason is required.');
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            className='mt-8 space-y-6'
            noValidate
            autoComplete='off'
          >
            <div className={`${Classes.addContent} py-2`}>
              <div className='mb-3'>
                <p className={Classes.label}> <b>{organization?.role === `user`? "User": "Nonprofit organization"}</b></p>
                <input
                  disabled
                  type='text'
                  name='nonProfitOrganizationName'
                  value={organization?.nonProfitName || organization?.username}
                />
              </div>
              <div className='mb-3'>
                <p className={Classes.label}><b>Report Reason</b></p>

                <Radio.Group onChange={onChange} value={value}>
                  <Space direction='vertical'>
                    {radioOptions.map((radioOption) => (
                      <Radio key={radioOption.id} value={radioOption.id}>
                        {radioOption.title}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>

                {value === 14 ? (
                  <div className='mb-3 mt-4'>
                    <textarea
                      name='reason'
                      placeholder='Enter report reason'
                      onChange={(e) => {
                        handleReasonState(e);  // Formik's onChange handler
                        const reason = e.target.value;
                        if (reason.length < 50) {
                          setError('Reason should have at least 50 characters.');
                        } else if (reason.length > 600) {
                          setError('Maximum 600 characters allowed.');
                        } else {
                          setError('');
                        }
                      }}
                      className={Classes.txt_area}
                      rows={3}
                    ></textarea>
                  </div>
                ) : null}

                {error ? (
                  <span
                    className='mt-1 d-block'
                    size={'small'}
                    style={{ color: 'red' }}
                  >
                    <Fragment>{error}</Fragment>
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className='d-flex justify-content-center align-items-center gap-3 mt-4'>
                <button className={`${Classes.sendbtn} w-100`} type='submit'>
                  {loading ? (
                    <div className='d-flex justify-content-center align-items-center'>
                      <ThreeDots
                        height='30'
                        width='30'
                        radius='9'
                        color='white'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClassName=''
                        visible={true}
                      />
                    </div>
                  ) : (
                    organization?.role === `user`?'Report user':'Report organization'
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ReportNonProfitModal;
