import React from "react";
import { Modal } from "antd";
import Classes from "./modal.module.scss";
import { CHAIN_CURRENCY } from "../../helpers/common";
import { useSelector } from "react-redux";
import { FaVideo } from "react-icons/fa";

const ApprovedPurchase = ({
  isModalOpen,
  handleCancel,
  message,
  modalType,
  data,
  purchaseLoader,
  price
}) => {
  const {file, name, tokenId, blockchainType, primaryType } = data;
  const { exchangeRate } = useSelector((state) => state.nft);

  return (
    <Modal
      className={Classes.actionModal}
      title="Approve Purchase"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <div className={`${Classes.modalContent}`}>
        <div className={Classes.topRow}>
          <div className="d-flex align-items-center gap-3">
          {!file ? (
            <div className={Classes.preview}>x</div>
          ) : primaryType === "image" ? (
            <div className={`${Classes.preview}`}>
              <img src={file} alt="preview" />
            </div>
          ) : (
            <div className={`${Classes.preview} d-flex align-items-center justify-content-center`}>
              <FaVideo className={Classes.video} />
            </div>
          )}
            <div>
              <p>{name || ""}</p>
              <p>
                {" "}
                Token Id:
                <b>{tokenId || ""}</b>
              </p>
              <p>
                Chain:
                <b>
                  {blockchainType?.charAt(0).toUpperCase() +
                    blockchainType?.slice(1) || " "}
                </b>
              </p>
            </div>
          </div>
          <div>
            <p>
              <b>
                {price || ""} {CHAIN_CURRENCY[blockchainType]}{" "}
              </b>
            </p>
            <p>${(exchangeRate * price)?.toFixed(2)}</p>
          </div>
        </div>
        <div className="pt-4">
          <p>
            <b>
              {purchaseLoader
                ? "Your transaction is in progress please wait"
                : "Go to your wallet"}
            </b>
          </p>
          {!purchaseLoader && (
            <p>You’ll be asked to approve this purchase from your wallet.</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ApprovedPurchase;
