import React, { useEffect, useState } from "react";
import Classes from "../Activity/activity.module.scss";
import { Container } from "react-bootstrap";
import MainCard from "../MainCard";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { getPopularNFTs } from "../../store/_actions/nft_actions";
import { useSelector, useDispatch } from "react-redux";
const PopularNft = () => {
  const dispatch = useDispatch();
  const popularNfts = useSelector((state) => state?.nft?.popularNfts);
  const [params, setParams] = useState({
    causeFilter: "",
  });
  const settings = {
    dots: false,
    infinite: popularNfts?.length === 1 ? false : true,
    speed: 500,
    slidesToShow: popularNfts?.length >= 4 ? 4 : popularNfts?.length,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getPopularNFTs());
  }, []);
  useEffect(() => {
    dispatch(getPopularNFTs(params));
  }, [params.causeFilter]);
  return (
    <div className={Classes.activity}>
      <Container>
        <div className="d-flex align-items-center gap-3 mb-4">
          <h1 className={Classes.heading}>Popular NFTs</h1>
          <Link to="/popular-nfts" className={Classes.seeAllBtn}>
            See More
          </Link>
        </div>
        <Slider {...settings}>
          {popularNfts &&
            popularNfts?.length > 0 &&
            popularNfts.map((item, index) => (
              <MainCard
                item={item}
                key={index}
                params={params}
                setParams={setParams}
              />
            ))}
        </Slider>
      </Container>
    </div>
  );
};

export default PopularNft;
