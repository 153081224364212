
const { MetaMask } = require('@web3-react/metamask');
const { Network } = require('@web3-react/network');
const { WalletConnect: WalletConnectV2 } = require('@web3-react/walletconnect-v2');
const { initializeConnector } = require('@web3-react/core');
const { WalletConnect } = require('@web3-react/walletconnect-v2');

const infuraKey = process.env.REACT_APP_INFURA_KEY;
const alchemyKey = process.env.REACT_APP_INFURA_KEY;
const pocketNetwork = process.env.REACT_APP_POCKET_KEY;


const ETH = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
};

const MATIC = {
  name: 'Matic',
  symbol: 'MATIC',
  decimals: 18,
};

export const CHAINS = {
  1: {
    chainId: '1',
    urls: [
      infuraKey ? `https://mainnet.infura.io/v3/${infuraKey}` : '',
      alchemyKey ? `https://eth-mainnet.g.alchemy.com/v2/${alchemyKey}` : '',
      pocketNetwork
        ? `https://eth-mainnet.gateway.pokt.network/v1/lb/${pocketNetwork}`
        : '',
      'https://rpc.ankr.com/eth',
      'https://cloudflare-eth.com',
    ].filter(Boolean),
    publicUrls: ['https://rpc.ankr.com/eth'].filter(Boolean),
    name: 'Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://etherscan.io'],
  },
  // Polygon
  137: {
    chainId: '137',
    urls: [
      infuraKey
        ? `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
        : '',
      alchemyKey
        ? `https://polygon-mainnet.g.alchemy.com/v2/${alchemyKey}`
        : '',
      pocketNetwork
        ? `https://poly-mainnet.gateway.pokt.network/v1/lb/${pocketNetwork}`
        : '',
      'https://polygon-rpc.com',
    ].filter(Boolean),
    publicUrls: ['https://polygon-rpc.com'].filter(Boolean),
    name: 'Polygon',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  80001: {
    chainId: '80001',
    urls: [
      infuraKey
        ? `https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
        : '',
      alchemyKey ? `https://polygon-mumbai.g.alchemy.com/v2/${alchemyKey}` : '',
      'https://rpc-mumbai.maticvigil.com',
    ].filter(Boolean),
    publicUrls: ['https://rpc-mumbai.maticvigil.com'].filter(Boolean),
    name: 'Polygon Mumbai',
    nativeCurrency: MATIC,
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
  5: {
    chainId: '5',
    urls: [
      infuraKey
        ? `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
        : '',
      alchemyKey ? `https://eth-goerli.g.alchemy.com/v2/${alchemyKey}` : '',
      pocketNetwork
        ? `https://eth-goerli.gateway.pokt.network/v1/lb/${pocketNetwork}`
        : '',
      'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    ].filter(Boolean),
    publicUrls: ['https://rpc.ankr.com/eth_goerli'].filter(Boolean),
    name: 'Goerli',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://goerli.etherscan.io/'],
  },
  11155111: {
    chainId: '11155111',
    urls: [
      infuraKey
        ? `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
        : '',
      alchemyKey ? `https://eth-goerli.g.alchemy.com/v2/${alchemyKey}` : '',
      'https://ethereum-sepolia.publicnode.com',
    ].filter(Boolean),
    publicUrls: ['https://ethereum-sepolia.publicnode.com'].filter(Boolean),
    name: 'Sepolia',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://sepolia.etherscan.io/'],
  },
};

export const URLS = Object.keys(CHAINS).reduce((accumulator, chainId) => {
  const validURLs = CHAINS[Number(chainId)].urls;

  if (validURLs.length) {
    accumulator[Number(chainId)] = validURLs;
  }

  return accumulator;
}, {});

export const CHAINIDs = Object.keys(CHAINS).reduce((accumulator, chainId) => {
  const validCHAINIDs = CHAINS[Number(chainId)].chainId;

  if (validCHAINIDs) {
    accumulator[Number(chainId)] = validCHAINIDs;
  }

  return accumulator;
}, {});

export const [network, networkHooks] = initializeConnector((actions) => new Network({ actions, urlMap: URLS }));
export const [metaMask, metaMaskHooks] = initializeConnector((actions) => new MetaMask({ actions }));

export const [mainnet, ...optionalChains] = Object.keys(CHAINS).map(Number);

export const [walletConnect, walletConnectHooks] = initializeConnector((actions) => new WalletConnect({
  actions,
  options: {
    projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID ?? '',
    chains: [mainnet],
    optionalChains,
    showQrModal: true,
  },
}));

export function getName(connector) {
  if (connector instanceof MetaMask) return 'MetaMask';
  if (connector instanceof WalletConnectV2) return 'WalletConnect';
  if (connector instanceof Network) return 'Network';
  return 'Unknown';
}

export const connectors = [
  [metaMask, metaMaskHooks],
  [walletConnect, walletConnectHooks],
];

export const getConnecterByName = (name) => {
  const providers = {
    MetaMask: metaMask,
    WalletConneet: walletConnect,
  };

  if (providers[name]) {
    return providers[name];
  }
  return null
}



