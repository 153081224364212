import React, { useEffect, useState } from "react";
import Classes from "../UserTabs/tabs.module.scss";
import SearchIcon from "../../assets/images/icons/light-search.svg";
import { Col, Row, Select, Input } from "antd";
import NFTCard from "../NFTCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { friendRequestsList } from "../../services/friends.service";
import Advance from "../Advance";
import CommonModal from "../views/Pages/CommonModal";

const CollectionDetailTabs = ({
  nfts,
  params,
  setParams,
  collectionDetails,
}) => {
  const [show, setShow] = useState("all");
  const [friendsTabClicked, setFriendsTabClicked] = useState(false);
  const [friendRequests, setFriendRequests] = useState([]);
  const [filteredFriendRequests, setFilteredFriendRequests] = useState([]);
  const [selectedOption, setSelectedOption] = useState("interest");
  const [selectedOption2, setSelectedOption2] = useState("collection");
  const token = localStorage.getItem("authenticationToken");
  const { connectedAddress } = useSelector((state) => state.wallet);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const navigate = useNavigate();

  const getFriendsListing = async () => {
    try {
      const requests = await friendRequestsList(token);
      let friends = [];
      if (requests?.data?.data) {
        const receivedFriendRequests =
          requests.data.data.receivedFriendRequests || [];
        const sentFriendRequests = requests.data.data.sentFriendRequests || [];

        friends = [
          ...receivedFriendRequests.filter(
            (request) => request?.requestStatus === "approved"
          ),
          ...sentFriendRequests.filter(
            (request) => request?.requestStatus === "approved"
          ),
        ];
        setFriendRequests(friends);
        setFilteredFriendRequests(friends);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchFriendRequests = async (searchValue) => {
    try {
      const matchingObjects = friendRequests.filter((item) => {
        const { firstName, lastName, email } = item;
        return (
          firstName.toLowerCase().includes(searchValue?.toLowerCase()) ||
          lastName.toLowerCase().includes(searchValue?.toLowerCase()) ||
          email.toLowerCase().includes(searchValue?.toLowerCase())
        );
      });
      setFilteredFriendRequests(matchingObjects);

      if (matchingObjects?.length === 0) {
        getFriendsListing();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const showWalletModal = () => {
    setIsWalletModalOpen(true);
  };
  const handleWalletModalCancel = () => {
    setIsWalletModalOpen(false);
  };

  const handleChange = (value) => {
    setSelectedOption(value);
    setParams({ ...params, sortByPrice: value });
  };
  const handleChange2 = (value) => {
    setSelectedOption2(value);
  };

  useEffect(() => {
    if (friendsTabClicked) {
      setFriendsTabClicked(false);
    }
  }, [friendsTabClicked]);

  const handleFriendsTabClick = () => {
    setShow("friends");
    setFriendsTabClicked(true);
    getFriendsListing();
  };

  const filters = [
    {
      title: "All",
      value: "all",
    },
    {
      title: "Listed",
      value: "listed",
    },
    {
      title: "On Auction",
      value: "auction",
    },
    {
      title: "New",
      value: "new",
    },
  ];
  console.log(params, "params==>");

  return (
    <div className={`${Classes.userTabs}`}>
      <div
        className={`${Classes.tabsOuter} d-flex gap-lg-5 align-items-center  mb-4`}
      >
        <div className="d-flex gap-lg-5 gap-4 align-items-center mb-4 me-lg-0 me-4">
          {filters.map((item) => {
            return (
              <button
                onClick={() => {
                  if (item.value === "all" && params.itemFilter) {
                    setParams({ ...params, itemFilter: null });
                  } else {
                    setParams({ ...params, itemFilter: item.value });
                  }
                  setShow(item.value);
                }}
                className={
                  show === item.value
                    ? `${Classes.activeTab}`
                    : `${Classes.tab}`
                }
              >
                {item.title}
              </button>
            );
          })}
        </div>
        <div className="d-flex gap-lg-5 gap-4 align-items-center mb-4">
          <button
            onClick={() => setShow("advanced")}
            className={
              show === "advanced" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Advanced
          </button>
        </div>
      </div>
      <hr className="mb-3" />

      <>
        <div
          className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
        >
          <div className={`${Classes.searchOuter} d-flex gap-2`}>
            <img src={SearchIcon} alt="icon" />
            <Input
              className="w-100"
              type="text"
              placeholder="Search"
              onChange={(e) => setParams({ ...params, search: e.target.value })}
            />
          </div>
          <div className={`${Classes.rightSection} d-flex gap-4`}>
            <Select
              placeholder="Price"
              style={{
                width: 140,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 1,
                  label: "Low to High",
                },
                {
                  value: -1,
                  label: "High to Low",
                },
              ]}
            />
            <Select
              placeholder="Recently received"
              style={{
                width: 180,
              }}
              // onChange={handleChange}
              options={[
                {
                  value: "one",
                  label: "One Month Ago",
                },
                {
                  value: "two",
                  label: "Two Month Ago",
                },
              ]}
            />
            <button
              className={Classes.outlineBtn}
              onClick={() => {
                if (connectedAddress) {
                  navigate("/create-nft");
                } else {
                  showWalletModal();
                  setMessage(
                    "Please go to your profile and click on “Connect wallet” in order to enable NFT creation."
                  );
                  setModalType("Create NFT");
                }
              }}
            >
              Create NFT
            </button>
          </div>
        </div>
        {show === "listed" && (
          <Row gutter={[20, 20]}>
          {nfts &&
            nfts
              .filter(nft => nft.activeAuctions.some(auction => auction.sellingMethod === 1))
              .map((nft, index) => (
                <Col lg={6} md={12} sm={24} xs={24} key={index}>
                  <NFTCard nftList={nft} />
                </Col>
              ))}
        </Row>
        )}
         {show === "all" && (
          <Row gutter={[20, 20]}>
            {nfts &&
              nfts?.map((nft, index) => (
                <Col lg={6} md={12} sm={24} xs={24} index={index}>
                  <NFTCard nftList={nft} />
                </Col>
              ))}
          </Row>
        )}
         {show === "auction" && (
          <Row gutter={[20, 20]}>
          {nfts &&
            nfts
              .filter(nft => nft.activeAuctions.some(auction => auction.sellingMethod === 2))
              .map((nft, index) => (
                <Col lg={6} md={12} sm={24} xs={24} key={index}>
                  <NFTCard nftList={nft} />
                </Col>
              ))}
        </Row>
        )}
         {show === "new" && (
          <Row gutter={[20, 20]}>
            {nfts &&
              nfts?.map((nft, index) => (
                <Col lg={6} md={12} sm={24} xs={24} index={index}>
                  <NFTCard nftList={nft} />
                </Col>
              ))}
          </Row>
        )}
      </>
      {show === "advanced" && (
        <>
          <Advance
            collectionDetails={collectionDetails}
            collectionNfts={nfts}
          />
        </>
      )}
      <CommonModal
        showModal={showWalletModal}
        isModalOpen={isWalletModalOpen}
        handleCancel={handleWalletModalCancel}
        message={message}
        modalType={modalType}
      />
    </div>
  );
};

export default CollectionDetailTabs;
