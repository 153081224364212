import React from 'react';
import Cause from '../../Cause';
import New from '../../New';
import PopularNft from '../../PopularNft';
import LeaderBoard from '../../LeaderBoard';


const GuestHome = () => {
  return (
    <>
      
      <Cause />
      <New />
      <PopularNft />
      <LeaderBoard />
    </>
  );
};

export default GuestHome;
