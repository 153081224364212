import React, { useEffect, useState } from "react";
import Classes from "./tabs.module.scss";
import { Col, Row, Tooltip } from "antd";
import PublicNftCard from "../NFTCard/PublicNftCard";
import PublicFeatureCard from "../FeaturedCard/PublicFeatureCard";
import { useNavigate } from "react-router-dom";
import { collectionsListing } from "../../services/collections.service";
import Varified from "../../assets/images/icons/varified.svg";

const PublicUserTabs = ({ organization }) => {
  const [show, setShow] = useState("nftDrops");
  const [friendsTabClicked, setFriendsTabClicked] = useState(false);
  const [friendRequests, setFriendRequests] = useState([]);
  const [collections, setCollections] = useState();
  const navigate = useNavigate();
  const token = localStorage.getItem("authenticationToken");

  const getCollections = async () => {
    try {
      const response = await collectionsListing(token);
      if (response?.data?.data) {
        setCollections(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCollections();
  }, []);

  const handleFriends = () => {
    setShow("friends");
    let friends = [];
    const receivedFriendRequests = organization.receivedFriendRequests || [];
    const sentFriendRequests = organization.sentFriendRequests || [];

    friends = [
      ...receivedFriendRequests.filter(
        (request) => request?.requestStatus === "approved"
      ),
      ...sentFriendRequests.filter(
        (request) => request?.requestStatus === "approved"
      ),
    ];
    console.log("🚀 ~ useEffect ~ friends:", friends);
    setFriendRequests(friends);
  };

  useEffect(() => {
    if (friendsTabClicked) {
      setFriendsTabClicked(false);
    }
  }, [friendsTabClicked]);

  return (
    <div className={`${Classes.userTabs} pb-5`}>
      <div
        className={`${Classes.tabsOuter} d-flex gap-lg-5 align-items-center justify-content-lg-between mb-4`}
      >
        <div
          className={`${Classes.gap} d-flex align-items-center mb-4 me-lg-0 me-4`}
        >
          <>
            <button
              onClick={() => setShow("nftDrops")}
              className={
                show === "nftDrops" ? `${Classes.activeTab}` : `${Classes.tab}`
              }
            >
              NFT Drops
            </button>
            <button
              onClick={() => setShow("collections")}
              className={
                show === "collections"
                  ? `${Classes.activeTab}`
                  : `${Classes.tab}`
              }
            >
              Collections
            </button>
            <button
              onClick={() => setShow("causes")}
              className={
                show === "causes" ? `${Classes.activeTab}` : `${Classes.tab}`
              }
            >
              Causes
            </button>
            {organization?.role === "organization" && (
              <button
                onClick={() => setShow("mission")}
                className={
                  show === "mission" ? `${Classes.activeTab}` : `${Classes.tab}`
                }
              >
                Mission
              </button>
            )}
            {organization?.role === "user" && (
              <button
                onClick={() => setShow("charities")}
                className={
                  show === "charities"
                    ? `${Classes.activeTab}`
                    : `${Classes.tab}`
                }
              >
                Charities
              </button>
            )}
            {organization?.role === "user" && (
              <button
                onClick={() => handleFriends()}
                className={
                  show === "friends" ? `${Classes.activeTab}` : `${Classes.tab}`
                }
              >
                Friends
              </button>
            )}
          </>
        </div>
      </div>
      <hr className="mb-3" />

      {show === "nftDrops" && (
        <>
          <div className={Classes.outer}>
            <Row gutter={[20, 20]}>
              {organization && organization?.nftDrops?.length > 0 ? (
                organization?.nftDrops.map((nft) => (
                  <PublicFeatureCard data={nft} key={nft._id} />
                ))
              ) : (
                <p style={{ marginLeft: "40%", marginTop: "20px" }}>
                  No NFTs yet
                </p>
              )}
            </Row>
          </div>
        </>
      )}

      {show === "collections" && (
        <>
          <div className={Classes.outer}>
            <h1 className={Classes.title}>My Collection</h1>
            <Row gutter={[20, 20]}>
              {organization && organization?.collections?.length > 0 ? (
                organization?.collections.map((collection) => (
                  <PublicFeatureCard data={collection} key={collection._id} />
                ))
              ) : (
                <p style={{ marginLeft: "40%", marginTop: "20px" }}>
                  No collections yet
                </p>
              )}
            </Row>
          </div>
        </>
      )}
      {show === "causes" && (
        <>
          <Row gutter={[20, 20]}>
            {organization?.categories?.map((org) => (
              <Col lg={6} md={8} sm={12} xs={24} key={org?._id}>
                <PublicNftCard org={org} />
              </Col>
            ))}
          </Row>
        </>
      )}
      {show === "mission" && (
        <div className={`${Classes.projOuter}`}>
          <h1 className={Classes.title}>Mission</h1>
          <p className={`${Classes.value} text-break`}>{organization?.missionStatement}</p>
        </div>
      )}
      {show === "charities" && (
        <>
          <Row gutter={[20, 20]}>
            {organization?.charitiesList &&
            organization?.charitiesList.length > 0 ? (
              organization?.charitiesList?.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24} key={friend?.user?._id}>
                  <div className={Classes.columnContainer}>
                    <div className={Classes.column}>
                      <div className={Classes.item}>
                        <div
                          className="d-flex align-items-center gap-4 pb-4"
                          // style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   navigate(`/user/${friend?.user?.username}`)
                          // }
                        >
                          <div className="position-relative">
                            <img
                              className={Classes.userImg}
                              src={friend?.user?.profilePicture}
                              alt="img"
                            />
                            <img
                              className={Classes.varified}
                              src={Varified}
                              alt="img"
                            />
                          </div>
                          <div>
                            <h3
                              className={Classes.title}
                            >{`${friend?.user?.firstName} ${friend?.user?.lastName}`}</h3>
                            {friend?.type && friend?.fiatAmount && (
                              <div className="d-flex align-items-center gap-2">
                                <span className={Classes.subTxt}>
                                  Total Charity:
                                </span>
                                <div className="d-flex gap-50">
                                  <span className={Classes.subTxt}>
                                    {friend?.price.toFixed(8)}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="d-flex align-items-center gap-2">
                              <span className={Classes.subTxt}>Causes</span>
                              <div className="d-flex gap-50">
                                {friend?.user?.categories
                                  .slice(0, 8)
                                  .map((item, key) => (
                                    <Tooltip
                                      placement="top"
                                      title={item?.categoryName}
                                      key={key}
                                    >
                                      <img
                                        className={Classes.friendImg}
                                        src={item?.categoryImage}
                                        alt="img"
                                      />
                                    </Tooltip>
                                  ))}
                                {friend?.user?.categories.length > 8 && (
                                  <Tooltip
                                    placement="top"
                                    title={`+${friend?.user?.categories.length - 8} more`}
                                  >
                                    <div className={Classes.plusIcon}>
                                      +{friend?.user?.categories.length - 8}
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100">
                <p>Don't have any charities yet</p>
              </div>
            )}
          </Row>
        </>
      )}

      {show === "friends" && (
        <>
          <Row gutter={[20, 20]}>
            {friendRequests && friendRequests.length > 0 ? (
              friendRequests?.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24} key={friend?.user?._id}>
                  <div className={Classes.columnContainer}>
                    <div className={Classes.column}>
                      <div className={Classes.item}>
                        <div
                          className="d-flex align-items-center gap-4 pb-4"
                          // style={{ cursor: "pointer" }}
                          // onClick={() =>
                          //   navigate(`/user/${friend?.user?.username}`)
                          // }
                        >
                          <div className="position-relative">
                            <img
                              className={Classes.userImg}
                              src={friend?.user?.profilePicture}
                              alt="img"
                            />
                            <img
                              className={Classes.varified}
                              src={Varified}
                              alt="img"
                            />
                          </div>
                          <div>
                            <h3
                              className={Classes.title}
                            >{`${friend?.user?.firstName} ${friend?.user?.lastName}`}</h3>
                            {friend?.type && friend?.fiatAmount && (
                              <div className="d-flex align-items-center gap-2">
                                <span className={Classes.subTxt}>
                                  Total Charity:
                                </span>
                                <div className="d-flex gap-50">
                                  <span className={Classes.subTxt}>
                                    {friend?.price.toFixed(8)}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="d-flex align-items-center gap-2">
                              <span className={Classes.subTxt}>Causes</span>
                              <div className="d-flex gap-50">
                                {friend?.user?.categories
                                  .slice(0, 8)
                                  .map((item, key) => (
                                    <Tooltip
                                      placement="top"
                                      title={item?.categoryName}
                                      key={key}
                                    >
                                      <img
                                        className={Classes.friendImg}
                                        src={item?.categoryImage}
                                        alt="img"
                                      />
                                    </Tooltip>
                                  ))}
                                {friend?.user?.categories.length > 8 && (
                                  <Tooltip
                                    placement="top"
                                    title={`+${friend?.user?.categories.length - 8} more`}
                                  >
                                    <div className={Classes.plusIcon}>
                                      +{friend?.user?.categories.length - 8}
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100">
                <p>Don't have any friends yet</p>
              </div>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default PublicUserTabs;
