import React, { Fragment, useState } from "react";
import Classes from "../Login/auth.module.scss";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import { requestForgetPassword } from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/section/whiteLogo.svg";

const ForgetPasswordComp = () => {
  const [loading, setLoading] = useState(false);

  const forgetPassword = async (data) => {
    try {
      setLoading(true);
      const user = await requestForgetPassword(data);
      if (user && user.data.success === true) {
        alertMessage("success", `${user?.data?.message}`);
        setLoading(false);
      } else {
        alertMessage("error", `${user?.message}`);
        setLoading(false);
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setLoading(false);
      console.log("error.response", error.response.message);
    }
  };
  return (
    <div className={`${Classes.login} ${Classes.setpassword}`}>
      <Container>
        <Row className="d-flex justify-content-center align-items-center">
          <Col lg={13} md={24} sm={24} xs={24}>
          </Col>
          <Col lg={11} md={24} sm={24} xs={24}>
            <Link to='/' className={`${Classes.logo} mb-4 d-flex align-items-center justify-content-center`}>
              <img src={Logo} alt='logo' />
            </Link>
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <h2 className={Classes.heading}>Forgot Password</h2>
                <p className={Classes.desc}>Enter your Email Address</p>
              </div>
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Email is invalid.")
                    .trim()
                    .max(50, "Email must be at most 20 characters.")
                    .required("Email is required."),
                })}
                onSubmit={(values, { resetForm }) => {
                  const data = {
                    email: values.email,
                  };
                  forgetPassword(data);
                  // resetForm({ values: '' });
                }}
                textAlign="center"
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    className="mt-8 space-y-6"
                    noValidate
                    autoComplete="off"
                  >
                    <div className={Classes.formFields}>
                      <div className="mb-3">
                        <div className={`${Classes.inputOuter}`}>
                          <input
                            className="pe-3"
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email}
                          />
                        </div>
                        <span
                          className="d-block mt-1"
                          size={"small"}
                          style={{ color: "red" }}
                        >
                          {errors.email && touched.email ? (
                            <Fragment>{errors.email}</Fragment>
                          ) : null}
                        </span>
                      </div>
                    </div>

                    <div className={Classes.btnOuter}>
                      <button
                        className={`${Classes.blueBtn} mb-4`}
                        type="submit"
                      >
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              height="30"
                              width="30"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Send"
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgetPasswordComp;
