import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "../UserTabs/tabs.module.scss";
import SearchIcon from "../../assets/images/icons/light-search.svg";
import { Col, Row, Select, Modal } from "antd";
import NFTCard from "../NFTCard";
import { nftListing } from "../../services/nft.service";
import { Link } from "react-router-dom";
import FeaturedCard from "../FeaturedCard";
import { useSelector } from "react-redux";
import check from "../../assets/images/icons/check.svg";
import projIImg from "../../assets/images/section/projImg.svg";
import checkcircle from "../../assets/images/icons/varified.svg";
import LeaderBoardPublic from "../LeaderBoardPublic";
import CommonModal from "../views/Pages/CommonModal";

const OrgTabs = () => {
  const [show, setShow] = useState("causes");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [nftsList, setNftsList] = useState();
  const [selectedOption, setSelectedOption] = useState("interest");
  const [selectedOption2, setSelectedOption2] = useState("collection");
  const navigate = useNavigate();
  const { connectedAddress } = useSelector((state) => state.wallet);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showWalletModal = () => {
    setIsWalletModalOpen(true);
  };
  const handleWalletModalCancel = () => {
    setIsWalletModalOpen(false);
  };
  useEffect(() => {
    const getNftListing = async () => {
      try {
        const nfts = await nftListing();
        if (nfts && nfts?.data?.data?.length) {
          setNftsList(nfts?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getNftListing();
  }, []);

  const card = [1, 2, 3];
  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const handleChange = (value) => {
    setSelectedOption(value);
  };
  const handleChange2 = (value) => {
    setSelectedOption2(value);
  };
  return (
    <div className={`${Classes.userTabs} pb-5`}>
      <div
        className={`${Classes.tabsOuter} d-flex gap-lg-5 align-items-center justify-content-lg-between mb-4`}
      >
        <div className="d-flex gap-lg-5 gap-4 align-items-center mb-4 me-lg-0 me-4">
          <button
            onClick={() => setShow("causes")}
            className={
              show === "causes" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Causes
          </button>
          <button
            onClick={() => setShow("nftdrop")}
            className={
              show === "nftdrop" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            NFT Drops
          </button>
          <button
            onClick={() => setShow("verification")}
            className={
              show === "verification"
                ? `${Classes.activeTab}`
                : `${Classes.tab}`
            }
          >
            Verification
          </button>
          <button
            onClick={() => setShow("fiscal")}
            className={
              show === "fiscal" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Fiscal Projects
          </button>
        </div>
        <div className="d-flex gap-5 align-items-center mb-4">
          <button
            onClick={() => setShow("supporters")}
            className={
              show === "supporters" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Supporters
          </button>
          <button
            onClick={() => setShow("advanced")}
            className={
              show === "advanced" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Advanced
          </button>
        </div>
      </div>
      <hr className="mb-3" />

      {show === "causes" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input className="w-100" type="text" placeholder="Search" />
            </div>
            <div className={`${Classes.rightSection} d-flex gap-4`}>
              <Select
                placeholder="Price"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "low",
                    label: "Low to High",
                  },
                  {
                    value: "high",
                    label: "High to Low",
                  },
                ]}
              />
              <Select
                placeholder="Recently received"
                style={{
                  width: 180,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "low",
                    label: "Low to High",
                  },
                  {
                    value: "high",
                    label: "High to Low",
                  },
                ]}
              />
              <Link to="/" className={Classes.outlineBtn}>
                Donate NFT
              </Link>
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {data.map((i) => (
              <Col lg={6} md={8} sm={12} xs={24} key={i}>
                <NFTCard />
              </Col>
            ))}
          </Row>
        </>
      )}

      {show === "nftdrop" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input className="w-100" type="text" placeholder="Search" />
            </div>
            <div className={`${Classes.rightSection} d-flex gap-4`}>
              <Select
                placeholder="Price"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "low",
                    label: "Low to High",
                  },
                  {
                    value: "high",
                    label: "High to Low",
                  },
                ]}
              />
              <Select
                placeholder="Recently received"
                style={{
                  width: 180,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "one",
                    label: "One Month Ago",
                  },
                  {
                    value: "two",
                    label: "Two Month Ago",
                  },
                ]}
              />
              <button
                onClick={() => {
                  if (connectedAddress) {
                    navigate("/create-nft");
                  } else {
                    showWalletModal();
                    setMessage(
                      "Please go to your profile and click on “Connect wallet” in order to enable NFT creation."
                    );
                    setModalType("Create NFT");
                  }
                }}
                className={Classes.outlineBtn}
              >
                Drop NFT
              </button>
            </div>
          </div>
          <div className={Classes.outer}>
            <FeaturedCard />
          </div>
        </>
      )}
      {show === "verification" && (
        <>
          <Row className="mt-4" gutter={[20, 20]}>
            {card.map((i) => (
              <Col lg={8} md={24} sm={24} xs={24} key={i}>
                <div className={Classes.vertfication}>
                  <img src={check} alt="icon" />
                  <div>
                    <p>Verified by GuideStar</p>
                    <span>www.guidestar.com/url/verification/name</span>
                  </div>
                </div>
                {/* if pending show this */}

                {/* <div className={Classes.vertfication}>
                  <img src={pending} alt="icon" />
                  <div>
                    <p>Pending Verification by IRS</p>
                    <span>Pending</span>
                  </div>
                </div> */}

                {/* if rejected show this */}

                {/* <div className={Classes.vertfication}>
                  <img src={reject} alt="icon" />
                  <div>
                    <p>Rejected by IRS</p>
                    <span>Rejected</span>
                  </div>
                </div> */}
              </Col>
            ))}
          </Row>
        </>
      )}
      {show === "fiscal" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input className="w-100" type="text" placeholder="Search" />
            </div>
            <div className={`${Classes.rightSection} d-flex gap-4`}>
              <Select
                placeholder="Price"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "price",
                    label: "1234",
                  },
                ]}
              />
              <Select
                placeholder="Recent added"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "recent",
                    label: "Recent added",
                  },
                  {
                    value: "one",
                    label: "1 week ago",
                  },
                ]}
              />
              <button onClick={showModal} className={`${Classes.outlineBtn}`}>
                Add Project
              </button>
            </div>
          </div>
          <div className="background-white p-2">
            <Row gutter={[20, 20]}>
              {data.map((index) => (
                <Col lg={8} md={12} sm={24} xm={24} key={index}>
                  <div
                    className={`${Classes.projOuter} d-flex align-items-center gap-3`}
                  >
                    <div className="position-relative">
                      <img className={Classes.img} src={projIImg} alt="img" />
                      <img
                        className={`${Classes.check} position-absolute bottom-0`}
                        src={checkcircle}
                        alt="icon"
                      />
                    </div>
                    <div>
                      <p className={Classes.name}>Project name</p>
                      <div>
                        <span className={`${Classes.label} me-2`}>Email:</span>
                        <span className={Classes.value}>
                          Hello@organization.com
                        </span>
                      </div>
                      <div>
                        <span className={`${Classes.label} me-2`}>EIN:</span>
                        <span className={Classes.value}>04984902294883</span>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Modal
            title="Add Fiscal Projects"
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            className={Classes.projModal}
          >
            <div className={`${Classes.modalInner}`}>
              <div className={Classes.formInput}>
                <p className={Classes.label}>Project Image*</p>
                <div className="position-relative">
                  <div className="d-flex align-items-center gap-2">
                    <p className={Classes.uploadTxt}>Upload your Files</p>
                  </div>
                  <input
                    className={Classes.uploadInput}
                    type="file"
                    accept="image/*"
                  />
                </div>
              </div>
              <div className={Classes.formInput}>
                <p className={Classes.label}>organization Name*</p>
                <input placeholder="organization name" />
              </div>
              <div className={Classes.formInput}>
                <p className={Classes.label}>Email*</p>
                <input placeholder="email" />
              </div>
              <div className={Classes.formInput}>
                <p className={Classes.label}>EIN*</p>
                <input placeholder="ein" />
              </div>
              <div className={Classes.formInput}>
                <p className={Classes.label}>Percentage*</p>
                <input placeholder="percentage" />
              </div>
              <button className={Classes.blueBtn}>Add project</button>
            </div>
          </Modal>
        </>
      )}
      {show === "supporters" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input className="w-100" type="text" placeholder="Search" />
            </div>
            <div className={`d-flex gap-4`}>
              <Select
                placeholder="Price"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "price",
                    label: "1234",
                  },
                ]}
              />
              <Select
                placeholder="Recent added"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "recent",
                    label: "Recent added",
                  },
                ]}
              />
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {data.map((i) => (
              <Col lg={8} md={12} sm={24} xs={24} key={i}>
                <LeaderBoardPublic />
              </Col>
            ))}
          </Row>
        </>
      )}
      {show === "advanced" && <></>}
      <CommonModal
        showModal={showWalletModal}
        isModalOpen={isWalletModalOpen}
        handleCancel={handleWalletModalCancel}
        message={message}
        modalType={modalType}
      />
    </div>
  );
};

export default OrgTabs;
