import React from "react";
import Classes from "../LeaderBoardData/data.module.scss";
import { Tooltip } from "antd";
import Polygon from "../../assets/images/icons/polygon.svg";

const LeaderBoardPublic = ({ index, user }) => {
  const handleSplitName = (name) => {
    const displayText =
      name && name.length > 10 ? name.slice(0, 20) + "..." : name;

    return displayText;
  };
  return (
    <div className={Classes.columnContainer}>
      <div className={Classes.column}>
        <div className={Classes.item}>
          <div className="d-flex align-items-center gap-4 pb-4">
            <div className="position-relative">
              {/* <span className={`${Classes.number} px-3`}>{user}</span> */}
              <img
                className={Classes.userImg}
                src={index?.profilePicture}
                alt="img"
              />
              {/* <img className={Classes.varified} src={Varified} alt="img" /> */}
            </div>
            <div>
            <h3 className={Classes.title}>
              {index?.role === "user" ? (
                index?.firstName.length > 10 || index?.lastName.length > 10 ? (
                  <Tooltip title={index?.firstName + " " + index?.lastName}>
                    {handleSplitName(index?.firstName)}
                  </Tooltip>
                ) : (
                  <>
                    {index?.firstName} {index?.lastName}
                  </>
                )
              ) : (
                index?.nonProfitName.length > 10 ? (
                  <Tooltip title={index?.nonProfitName}>
                    {handleSplitName(index?.nonProfitName)}
                  </Tooltip>
                ) : (
                  index?.nonProfitName
                )
              )}
            </h3>

              <div className="d-flex align-items-center gap-2">
                <span className={Classes.subTxt}>Charity Donations</span>
                <div className="d-flex gap-2">
                  <span className={Classes.price}>
                    {index?.totalDonations?.toFixed(7)}
                  </span>
                  <img src={Polygon} alt="img" />
                </div>
              </div>
              {/* <div className="d-flex align-items-center gap-2">
                <span className={Classes.subTxt}>Floor Price</span>
                <div className="d-flex gap-2">
                  <span className={Classes.price}>
                    {(index?.floorPrice && index?.floorPrice?.toFixed(3)) || 0}
                  </span>
                  <img src={Polygon} alt="img" />
                </div>
              </div> */}
            </div>
          </div>
          <div>
            <span className={Classes.amount}>NFT Count</span>
            <div className="d-flex gap-2">
              <span className={Classes.polygonTxt}>{index?.nftCount}</span>
              {/* <img src={Polygon} alt="img" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardPublic;
