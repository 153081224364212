import React, { useEffect, useState } from "react";
import Classes from "./causeDetailComp.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row, Select } from "antd";
import SearchMain from "../SearchMain";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../store/_actions/categories_actions";
import Check from "../../assets/images/icons/check_circle.svg";
import { updateUserCategory } from "../../services/categories.service";
import { alertMessage } from "../../constants/alertMessage";
import { thunkLogin } from "../../store/_actions/user_action";
import { useNavigate } from "react-router-dom";
import NotificationAlert from "../Alert";


const EditCauseDetailComp = ({ title }) => {
  const [show, setshow] = useState(false);
  const [filterCategories, setFilterCategories] = useState("");
  const { categories, allCategories } = useSelector((state) => state?.categories);
  const auth = useSelector((state) => state?.auth);
  const [filteredData, setFilteredData] = useState(null);
  const [data, setData] = useState(null);
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showCauseError, setShowCauseError] = useState(false);


  useEffect(() => {
    const { username, id } = auth || {};
    if (id && username) {
      dispatch(getCategories());
    }
  }, [auth]);

  useEffect(() => {
    const { username, id } = auth || {};
    if (!username && !id) {
      navigate("/login");
    } else if (!username && id) {
      navigate("/get-started");
    }
  }, [auth]);

  useEffect(() => {
    const { username, id } = auth || {};
    if (categories && id && username) {
      filterData(categories, setFilteredData);
      filterData(allCategories, setData);
    }
  }, [categories]);

  const filterData = (data, setData) => {
    const filteredData = data && data.map((item) => ({
      ...item,
      selected: auth?.categories?.some(
        (category) => category?.categoryName?.toLowerCase() === item?.categoryName?.toLowerCase()
      ),
    }));
    setData(filteredData);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    if (value?.length >= 3) {
      dispatch(getCategories({ search: value }));
    } else if (value?.length === 0) {
      dispatch(getCategories());
    }
  };

  const handleOnClick = async (id) => {
    const updatedData = data?.map((category) =>
      category._id === id
        ? { ...category, selected: !category?.selected }
        : category
    );
    const selectedCategories = updatedData
      ?.filter((category) => category.selected)
      ?.map((category) => category._id);
    if (!selectedCategories?.length) {
      return setShowCauseError(true)
    }
    setFilteredData(updatedData);
    const response = await updateUserCategory(auth?.id, selectedCategories);
    alertMessage("success", "Causes updated successfully", 1);
    setSearch('');
    dispatch(thunkLogin(response?.data?.data, () => {}));
  };

  return (
    <div className={Classes.causeDetailComp}>
      <Container>
        <div className="d-flex align-items-md-center justify-content-between mb-4 flex-md-row flex-column">
          <div className="d-flex align-items-center gap-4">
            <h1 className={`${Classes.title} mb-0`}>{title || "-"}</h1>
            <button className={Classes.blueBtn} onClick={() => navigate(-1)}>Done</button>
          </div>
          <div className="d-flex gap-4">
          <Select
              placeholder="Select/unselect Cause"
              style={{ width: 180 }}
              value={filteredData?.filter(category => category.selected).map(category => ({
                value: category._id,
                label: category.categoryName
              }))}
              onChange={(selectedOption) => handleOnClick(selectedOption)}
              options={filteredData?.map(category => ({
                value: category._id,
                label: category.categoryName
              }))}
            />
          </div>
          {/* <div className={Classes.searchIcon} onClick={() => setshow(!show)}>
            <img src={Search} alt="img" />
          </div> */}
        </div>
        {show && (
          <div className="mb-4">
            <SearchMain onChange={handleChange} value={search} />
          </div>
        )}
        <Row gutter={[20, 20]}>
          {filteredData && filteredData?.map((category, index) => (
            <Col lg={6} md={8} sm={12} xs={24} key={index}>
              <div
                className={Classes.causeOuter}
                onClick={() => handleOnClick(category?._id)}
              >
                <img
                  className={Classes.img}
                  src={category?.categoryImage}
                  alt="img"
                />
                <div
                  className={`${Classes.bottom} d-flex justify-content-center gap-1 align-items-center px-2 py-1 mt-1`}
                >
                  <h2 className={Classes.txt}>{category?.categoryName}</h2>
                  {category?.selected && (
                    <img className={Classes.check} src={Check} alt="img" />
                  )}
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      {showCauseError && <NotificationAlert title="Select Cause" content='Please select one or more causes.' />}

    </div>
  );
};

export default EditCauseDetailComp;
