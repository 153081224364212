import Home from "../components/views/Pages/Home";
import Login from "../components/views/Pages/Login";
import SignUp from "../components/views/Pages/Signup";
import EmailVerification from "../components/EmailVerification";
import SetPassword from "../components/SetPassword";
import VerifyEmail from "../components/VerifyEmail";
import AcceptFiscalSponsorshipInvite from "../components/AcceptFiscalSponsorshipInvite";
import ForgetPassword from "../components/ForgetPassword";
import CharityNft from "../components/CharityNFT/index";
import BuildProfile from "../components/BuildProfile";
import BuildOrganizationProfile from "../components/BuildOrganizationProfile";
import Selection from "../components/Selection";
import UserDetail from "../components/UserDetail";
import FiscalSponsorAgreement from "../components/UserDetail/FiscalSponsorAgreement";
import UploadDocument from "../components/views/Pages/UploadDocument";
import UserProfileView from "../components/views/Pages/UserProfileView";
import PublicProfile from "../components/views/Pages/PublicProfile";
import CreateNft from "../components/views/Pages/CreateNft";
import ViewNft from "../components/views/Pages/ViewNft";
import CreateCollection from "../components/views/Pages/CreateCollection";
import FriendsDetails from "../components/views/Pages/FriendsDetails";
import CauseDetails from "../components/views/Pages/CauseDetails";
import FeaturedNftDetail from "../components/views/Pages/FeaturedNftDetail";
import FeaturedOrgDetail from "../components/views/Pages/FeaturedOrgDetail";
import NftListing from "../components/views/Pages/NftListing";
import PopularNfts from "../components/views/Pages/PopularNfts";
import Listing from "../components/views/Pages/Listing";
import OrgProfileView from "../components/views/Pages/OrgProfileView";
import CollectionDetail from "../components/views/Pages/CollectionDetail";
import Settings from "../components/views/Pages/Settings";
import Notifications from "../components/views/Pages/Notifications";
import Collection from "../components/views/Pages/Collection";
import Explore from "../components/views/Pages/Explore";
import LeaderBoardPage from "../components/views/Pages/LeaderBoardPage";
import WalletConnect from "../components/views/Pages/WalletConnect";
import NotFound from "../components/views/Pages/404";
import NftNotFound from "../components/views/Pages/nft404";
import EditCause from "../components/views/Pages/EditCause";
import FAQ from "../components/FAQ";
import Security from '../components/security';
import TermsCondition from "../components/views/Pages/TermsCondition";
import Banned from "../components/views/Pages/Banned";
// import FiscalSponsorLetter from '../components/views/Pages/FiscalSponsorLetter';

export const routes = [
  {
    name: "Home",
    url: "/",
    component: Home,
    isPublic: false,
  },
  {
    name: "PublicProfile",
    url: "/user/:organizationName",
    component: PublicProfile,
    isPublic: true,
  },
  {
    name: "Login",
    url: "/login",
    component: Login,
    isPublic: false,
  },
  {
    name: "SignUp",
    url: "/sign-up",
    component: SignUp,
    isPublic: false,
  },
  {
    name: "EmailVerification",
    url: "/email-verification",
    component: EmailVerification,
    isPublic: false,
  },
  {
    name: "SetPassword",
    url: "/set-password/:token",
    component: SetPassword,
    isPublic: false,
  },
  {
    name: "VerifyEmail",
    url: "/email-verification/:token",
    component: VerifyEmail,
    isPublic: false,
  },
  {
    name: "SetPassword",
    url: "/sponsorship-invite/:token",
    component: AcceptFiscalSponsorshipInvite,
    isPublic: false,
  },
  {
    name: "ForgetPassword",
    url: "/forget-password",
    component: ForgetPassword,
    isPublic: false,
  },
  {
    name: 'Security',
    url: '/security',
    component: Security,
    isPublic: false,
  },
  {
    name: 'CharityNft',
    url: '/get-started',
    component: CharityNft,
    isPublic: false,
  },
  {
    name: "BuildProfile",
    url: "/build-profile",
    component: BuildProfile,
    isPublic: false,
  },

  {
    name: "BuildOrganizationProfile",
    url: "/build-organization-profile",
    component: BuildOrganizationProfile,
    isPublic: false,
  },
  {
    name: "Selection",
    url: "/selection",
    component: Selection,
    isPublic: false,
  },
  {
    name: "UserInfo",
    url: "/user-info",
    component: UserDetail,
    isPublic: false,
  },
  {
    name: "FiscalSponsorAgreement",
    url: "/fiscal-sponsor-agreement",
    component: FiscalSponsorAgreement,
    isPublic: false,
  },
  {
    name: "UploadDocument",
    url: "/upload-document",
    component: UploadDocument,
    isPublic: false,
  },
  {
    name: "UserProfile",
    url: "/profile",
    component: UserProfileView,
    isPublic: false,
  },
  {
    name: "UserProfile",
    url: "/create-nft",
    component: CreateNft,
    isPublic: false,
  },
  {
    name: "ViewNFT",
    url: "/nft/:id",
    component: ViewNft,
    isPublic: false,
  },
  {
    name: "CreateCollection",
    url: "/create-collection",
    component: CreateCollection,
    isPublic: false,
  },
  {
    name: "FriendsDetails",
    url: "/friends-detail",
    component: FriendsDetails,
    isPublic: false,
  },
  {
    name: "CauseDetail",
    url: "/cause-detail",
    component: CauseDetails,
    isPublic: false,
  },
  {
    name: "CauseDetail",
    url: "/edit-cause",
    component: EditCause,
    isPublic: false,
  },
  {
    name: "FeaturedNftDetail",
    url: "/featured-nft-detail",
    component: FeaturedNftDetail,
    isPublic: false,
  },
  {
    name: "FeaturedOrgDetail",
    url: "/featured-organization-detail",
    component: FeaturedOrgDetail,
    isPublic: false,
  },
  {
    name: "NftListing",
    url: "/nft-listing",
    component: NftListing,
    isPublic: false,
  },
  {
    name: "PopularNfts",
    url: "/popular-nfts",
    component: PopularNfts,
    isPublic: false,
  },
  {
    name: "LeaderBoardPage",
    url: "/leaderboards",
    component: LeaderBoardPage,
    isPublic: false,
  },
  {
    name: "Listing",
    url: "/listing/:id",
    component: Listing,
    isPublic: false,
  },
  {
    name: "organizationProfile",
    url: "/organization-profile",
    component: OrgProfileView,
    isPublic: false,
  },
  {
    name: "Collection",
    url: "/collections",
    component: Collection,
    isPublic: false,
  },
  {
    name: "Explore",
    url: "/explore",
    component: Explore,
    isPublic: false,
  },
  {
    name: "Collection",
    url: "/collection-detail/:id",
    component: CollectionDetail,
    isPublic: false,
  },
  {
    name: "Settings",
    url: "/setting",
    component: Settings,
    isPublic: false,
  },
  {
    name: "WalletConnect",
    url: "/wallet-connect",
    component: WalletConnect,
    isPublic: false,
  },
  {
    name: "PageNotFound",
    url: "/not-found",
    component: NotFound,
    isPublic: false,
  },
  {
    name: "NftNotFound",
    url: "/nft-not-found",
    component: NftNotFound,
    isPublic: false,
  },
  {
    name: "Notifications",
    url: "/notifications",
    component: Notifications,
    isPublic: false,
  },
  {
    name: "FAQ",
    url: "/faq",
    component: FAQ,
    isPublic: false,
  },
  {
    name: "Term",
    url: "/term-and-conditions",
    component: TermsCondition,
    isPublic: false,
  },
  {
    name: "Banned",
    url: "/hidden-profile",
    component: Banned,
    isPublic: false,
  },
];
