import {
  SET_NFTS,
  SHOW_NFTS_LIST_LOADER,
  HIDE_NFTS_LIST_LOADER,
  SET_POPULAR_NFTS,
  SHOW_POPULAR_NFTS_LIST_LOADER,
  HIDE_POPULAR_NFTS_LIST_LOADER,
  SET_NFT,
  SHOW_NFT_LIST_LOADER,
  HIDE_NFT_LIST_LOADER,
  SET_EXCHANGE_RATE,
  SET_POLY_EXCHANGE_RATE,
} from "../constants";

const initialWalletState = {
  nfts: null,
  nftsListLoader: false,
  popularNfts: null,
  popularNftsListLoader: false,
  nft: null,
  nftLoader: false,
  exchangeRate: null,
};

export const NftReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_NFTS:
      return {
        ...state,
        nfts: action.payload,
      };
    case SHOW_NFTS_LIST_LOADER:
      return {
        ...state,
        nftsListLoader: true,
        // nfts: null,
      };
    case HIDE_NFTS_LIST_LOADER:
      return {
        ...state,
        nftsListLoader: false,
      };
    case SET_POPULAR_NFTS:
      return {
        ...state,
        popularNfts: action.payload,
      };
    case SHOW_POPULAR_NFTS_LIST_LOADER:
      return {
        ...state,
        popularNftsListLoader: true,
        // popularNfts: null,
      };
    case HIDE_POPULAR_NFTS_LIST_LOADER:
      return {
        ...state,
        popularNftsListLoader: false,
      };
    case SET_NFT:
      return {
        ...state,
        nft: action.payload,
      };
    case SHOW_NFT_LIST_LOADER:
      return {
        ...state,
        nftLoader: true,
      };
    case HIDE_NFT_LIST_LOADER:
      return {
        ...state,
        nftLoader: false,
      };
    case SET_EXCHANGE_RATE:
      return {
        ...state,
        exchangeRate: action.payload,
      };
    case SET_POLY_EXCHANGE_RATE:
      return {
        ...state,
        polyExchangeRate: action.payload,
      };
    default:
      return state;
  }
};
