import React from "react";
import Classes from "./nftInformation.module.scss";
import { Collapse } from "antd";
import { ENV } from "../../config";
const { Panel } = Collapse;

function NftInformation({ data }) {
  const { description, tokenId } = data || {
    description: "",
    tokenId: 0,
  };
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className={Classes.nftInfo}>
      <div className={Classes.header}>
        <Collapse defaultActiveKey={["1"]} onChange={onChange}>
          <Panel header="Description" key="1">
            <div className={Classes.panel}>
              <p className={Classes.txt}>
                By{" "}
                <span className={Classes.darktxt}>
                  {data?.userId?.role === "user" ? data?.userId?.firstName:data?.userId?.nonProfitName}
                </span>
              </p>
              <p className={Classes.desc}>{description || ""}</p>
            </div>
          </Panel>
          {/* <Panel header="About The Beacon" key="2">
            <div className={Classes.panel}>
              <p className={Classes.desc}>
                This collection has no description yet.
              </p>
            </div>
          </Panel> */}
          <Panel header="Details" key="3">
            <div className={Classes.panel}>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p className={Classes.darktxt}>Contract Address</p>
                <p className={`${Classes.bluetxt}`}>
                  {ENV?.marketPlaceAddr?.slice(0, 6) +
                    "..." +
                    ENV?.marketPlaceAddr?.slice(-6)}
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p className={Classes.darktxt}>Token ID</p>
                <p className={`${Classes.bluetxt}`}>{tokenId || 0}</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4">
                <p className={Classes.darktxt}>Token Standard</p>
                <p>ERC-1155</p>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <p className={Classes.darktxt}>Chain</p>
                <p>Polygon</p>
              </div>
            </div>
          </Panel>
        </Collapse>

        {/* <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="Details" key="1">
            <div>
              <p className={`${Classes.txt} mb-4`}>
                A collection of 10,000 undead NFTs minted on the Ethereum
                blockchain. Each unique Deadfella is randomly generated from a
                combination of over 400 individually.
              </p>
              <p className={`${Classes.txt} mb-4`}>
                A collection of 10,000 undead NFTs minted on the Ethereum
                blockchain. Each unique Deadfella is randomly generated.
              </p>
              <ul className={Classes.listOuter}>
                <li>
                  <div className="d-flex gap-5 mb-3">
                    <p className={`${Classes.txt} w-25`}>Blockchain</p>
                    <div className="d-flex align-items-center gap-1">
                      <img src={Polygon} alt="icon" />
                      <p className={Classes.boldTxt}>Polygon</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex gap-5 mb-3">
                    <p className={`${Classes.txt} w-25`}>Contract Address</p>
                    <p className={Classes.boldTxt}>0x330cd8fec...8b7c</p>
                  </div>
                </li>
                <li>
                  <div className="d-flex gap-5 mb-3">
                    <p className={`${Classes.txt} w-25`}>Metadeta</p>
                    <p className={Classes.boldTxt}>Centralized</p>
                  </div>
                </li>
              </ul>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Bids" key="2">
            Content of Tab Pane 2
          </Tabs.TabPane>
          <Tabs.TabPane tab="Activity" key="3">
            Content of Tab Pane 3
          </Tabs.TabPane>
        </Tabs> */}
      </div>
    </div>
  );
}

export default NftInformation;
