import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "./friendSuggestion.module.scss";
import { Tooltip } from "antd";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { friendsList, addNewFriend } from "../../services/friends.service";
import { useSelector } from "react-redux";
import { alertMessage } from "../../constants/alertMessage";
import { ThreeDots } from "react-loader-spinner";
import CommonModal from "../views/Pages/CommonModal";

const FriendSuggestion = ({ searchQuery }) => {
  const navigate = useNavigate();
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const auth = useSelector((state) => state?.auth);
  const currentUrl = window.location.href;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let limit = 10;
  if (currentUrl.includes("friends-detail")) {
    limit = 100;
  }

  const getFriendsList = async () => {
    try {
      const friendsData = await friendsList(auth?.categories, limit);
      if (friendsData?.data?.data) {
        setFriends(friendsData?.data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getFriendsList();
  }, []);

  const addFriend = async (friendId) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: true }));
      const data = { friendId, userId: auth?.id, requestStatus: "pending" };
      const friend = await addNewFriend(data);
      if (friend?.data) {
        showModal();
        setMessage(friend?.data?.message);
        setModalType("Request Sent");
        getFriendsList();
        setLoading((prevLoading) => ({ ...prevLoading, [friendId]: false }));
      }
    } catch (error) {
      console.log(error);
      alertMessage("error", `${error}`);
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: false }));
    }
  };

  const filterFriends = (friends) => {
    return friends.filter((friend) =>
      friend?.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      friend?.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const filteredFriends = filterFriends(friends);

  return (
    <div className={Classes.friendSuggestion}>
      {auth?.role !== "organization" && (
        <Container>
          {!currentUrl.includes("friends-detail") && (
            <div className="d-flex align-items-center gap-3 mb-4">
              <h1 className={Classes.heading}>Friends Suggestions</h1>
              {filteredFriends && filteredFriends.length > 0 && (
                <Link to="/friends-detail" className={Classes.seeAllBtn}>
                  See More
                </Link>
              )}
            </div>
          )}

          <div className="d-flex align-items-center gap-4 flex-wrap">
            {filteredFriends && filteredFriends.length > 0
              ? filteredFriends?.map((friend, key) => (
                  <div className={Classes.outer} key={key}>
                    <div
                      className="d-flex justify-content-center align-items-center flex-column gap-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/user/${friend?.username}`)}
                    >
                      <img
                        className={Classes.userImg}
                        src={friend?.profilePicture}
                        alt="img"
                      />
                      <p
                        className={Classes.username}
                      >{`${friend?.firstName} ${friend?.lastName}`}</p>
                    </div>
                    <div className="d-flex flex-column align-items-center gap-2 py-3">
                      <p className={Classes.txt}>Shared Causes</p>
                      <div className="d-flex align-items-center gap-50">
                        {friend?.categories.slice(0, 7).map((item, key) => (
                          <Tooltip
                            placement="top"
                            title={item?.categoryName}
                            key={key}
                          >
                            <img
                              className={Classes.friendImg}
                              src={item?.categoryImage}
                              alt="img"
                            />
                          </Tooltip>
                        ))}
                        {friend?.categories.length > 7 && (
                          <Tooltip
                            placement="top"
                            title={`+${friend?.categories.length - 7} more`}
                          >
                            <div className={Classes.plusIcon}>
                              +{friend?.categories.length - 7}
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                    <button
                      className={Classes.outlineBtn}
                      onClick={() => addFriend(friend?._id)}
                      disabled={loading[friend?._id]}
                    >
                      {loading[friend?._id] ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="#d9d9d9"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Add Friend"
                      )}
                    </button>
                  </div>
                ))
              : !isLoading && (
                  <p
                    style={
                      currentUrl.includes("friends-detail")
                        ? { marginLeft: "25%" }
                        : {}
                    }
                  >
                    Please update your causes in case to get friend suggestions
                    of similar interest.
                  </p>
                )}
          </div>
        </Container>
      )}

      <CommonModal
        showModal={showModal}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        message={message}
        modalType={modalType}
      />
    </div>
  );
};

export default FriendSuggestion;
